@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/nova/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.css";

/* You can add global styles to this file, and also import other style files */

html,
body {
  /* make sure the body does not have a margin */
  margin: 0;
  /* this forces the body tag to fill the height of the window */
  height: 100%;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
  font-size: 16px;
}

.text-danger {
  color: #d20020;
}

h1 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 2rem;
  color: #595959;
  margin: 0.5rem 0;
}

h2 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.5rem;
  color: #595959;
  margin: 0.5rem 0;
}

h3 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.1rem;
  color: #595959;
  margin: 0.5rem 0;
}

h4 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 0.95rem;
  margin: 0.5rem 0;
  color: #595959;
}

h5 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 0.85rem;
  margin: 0.25rem 0;
}

h6 {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 0.65rem;
  color: #595959;
  margin: 0.5rem 0;
}

.h1-header {
  font-weight: 600;
  font-size: 1.75rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.h2-header {
  font-weight: 600;
  font-size: 1.5rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.h3-header {
  font-weight: 600;
  font-size: 0.95rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.h4-header {
  font-weight: 600;
  font-size: 0.9rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.h5-header {
  font-weight: 600;
  font-size: 0.75rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.h6-header {
  font-weight: 600;
  font-size: 0.5rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.input-border {
  border: 1px solid #808080 !important;
}

.ui-button {
  background-color: #1c798e !important;
  border-radius: 0px !important;
  width: 125px;
}
.icon-color {
  color: #1c798e !important;
}

.ui-button:active,
.ui-button:focus {
  border: transparent !important;
  box-shadow: none !important;
}

body a:hover {
  text-decoration: none;
  color: inherit;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.margin-right {
  margin-right: 5px !important;
}

.margin {
  margin: 2px 2px 2px 2px;
}

.margin-left {
  margin-left: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.control-label {
  font-size: 16px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 0 0 15px;
}

.error-label {
  color: #d20020 !important;
  font-size: 0.85rem;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.footer {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  bottom: 0px;
  background-color: #c6c5c5;
  width: 100%;
  line-height: 50px;
}

.page-layout > div {
  background-color: #f2f2f2;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 4px;
}

.page-layout {
  /* the .app div needs to be AT LEAST 100% of the height of the window */
  min-height: calc(100vh - 95px);
  /* now that it is 100% the height, we 'pull' the footer up */
  /* margin-bottom must be the same height as the footer height below */
  margin: 0;
}

.base-table {
  p-dropdown {
    .ui-dropdown {
      min-width: 0;
    }
  }
}

.align-center {
  text-align: center !important;
}

.align-left {
  text-align: left !important;
}

.p-datatable table {
  border-bottom: 1px solid #7f7f7f;
}

.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
  background-color: #7f7f7f !important;
  font-weight: normal !important;
  color: white !important;
  padding: 0.25rem;
  // text-align : left !important;
}

// p-accordiontab .p-datatable .p-datatable-thead > tr > th {
//   background-color: #ffffff !important;
//   font-weight: bold !important;
//   color: #595959 !important;
// }

.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
  padding: 0.25rem 0.45rem;
  font-size: 0.8rem;
}

.ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  color: white !important;
  box-shadow: none !important;
}

.ui-dropdown:not(.ui-state-disabled):not(.ui-state-focused) {
  box-shadow: none !important;
  border-color: none !important;
}

.ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body
  .ui-dropdown-panel
  .ui-dropdown-items
  .ui-dropdown-item-group.ui-state-highlight {
  background-color: #1c798e;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox .p-checkbox-box[aria-checked="true"] {
  border-color: black;
  background-color: white;
  cursor: pointer;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: black;
}

.ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body
  .ui-dropdown-panel
  .ui-dropdown-items
  .ui-dropdown-item-group.ui-state-highlight {
  background-color: #1c798e !important;
}

.ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #1c798e !important;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 5em;
  color: #1c798e !important;
}

.full-width {
  width: 100%;
}

.ui-button.ui-state-default.ui-button-danger,
body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  background-color: none !important;
  border: none !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

body .p-datatable .p-datatable-thead > tr > th {
  background-color: #7f7f7f;
  font-weight: normal;
  color: #ffffff;
  border: none;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #f9f9f9;
}

.small-button {
  width: 100px;
}

.ui-dialog .ui-dialog-titlebar {
  font-size: larger;
}

.p-col-2 {
  width: 12.6667%;
}

.p-col-5 {
  width: 39% !important;
}

.schoolDropdown {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  display: inline;
}

.window-full-width {
  padding: 0px;
  margin: 0px;
}

.p-margin-top {
  margin-top: 0px;
}

.base-table .ui-dropdown .ui-dropdown-label {
  font-family: "Courier New" !important;
  font-size: 15px !important;
}

.Courier-New-font .ui-widget {
  font-family: "Courier New" !important;
  font-size: 15px !important;
}

.dashboard-font-white {
  color: white;
}

.dashboard-font-black {
  color: black;
}

.schooldashboard-grid-item {
  background-color: teal;
  padding: 20px;
  font-size: 20px;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  text-align: left;
  margin: 5px;
  min-height: 200px;
}

.hyperlink {
  text-decoration: underline;
  cursor: pointer;
}

.ui-toast-detail {
  white-space: pre-line;
}

.cursor-pointer {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

body .p-menubar .p-menubar-root-list > .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 0 0 0 1px;
}

.p-button {
  background-color: #1c798e !important;
  border-radius: 0px !important;
  width: auto;
  min-width: 125px;
  max-width: 225px;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus,
.p-datatable .p-sortable-column:focus,
.p-button:focus,
.p-inputtext:enabled:focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-datepicker:focus,
.p.p-listbox:focus {
  box-shadow: inset 0 0 0 0.2rem #ffdc00;
}

.p-datatable-scrollable-body,
.p-datatable-unfrozen-view .p-datatable-scrollable-body {
  padding-bottom: 0.5rem;
}

.card-height {
  height: 100px;
  margin-right: 50px;
}

.p-card {
  background: #ffffff;
  color: #d9d9d9;
  box-shadow: none;
  border: 1px solid;
  border-radius: 0px;
  height: 8rem;
}

.width-layout {
  width: 90%;
  margin: auto;
  height: auto;
}

.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  margin-top: -8px;
  position: absolute;
  right: 0.5em;
  top: 50%;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #d9d9d9;
  border-color: #d9d9d9;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-color: #ffdc00;
  background: #7f7f7f;
  color: #fff;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #7f7f7f;
  border-color: #ffdc00;
  color: #fff;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 10px;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  color: #595959;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem 1rem;
  border: 1px solid #d9d9d9;
  color: #595959;
  background: #d9d9d9;
  font-weight: 600;
  border-radius: 0px;
  transition: background-color 0.2s, box-shadow 0.2s;
  font-size: 1.5rem;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #ffdc00;
}
.accordian-height {
  /* the div needs to be AT LEAST 100% of the height of the window */
  min-height: calc(100vh - 20px);
  /* now that it is 100% the height, we 'pull' the footer up */
}

.adjut-height {
  overflow: hidden;
}

.container {
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  display: flex;
}
.container .btn-holder {
  justify-content: flex-end;
  display: flex;
}

.p-card .p-card-title {
  font-size: 0.95rem;
  font-weight: bold;
  margin-bottom: 0rem;
  color: #595959;
}

.p-card .p-card-subtitle {
  font-weight: normal;
  margin-bottom: 0.75rem;
  color: #595959;
  font-size: 0.9rem;
}

.p-card .p-card-content {
  padding: 0rem 0;
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 0rem;
  color: #595959;
}

.p-card .p-card-body {
  padding: 0.25rem 0.75rem;
}

.p-accordion .p-accordion-content {
  padding: 0.25rem 0.75rem;
}

.p-button[label="Cancel"],
.p-button[label="Cancel"]:enabled:hover,
.p-button[label="Close"],
.p-button[label="Close"]:enabled:hover,
.p-button[ng-reflect-label="Cancel"],
.p-button[ng-reflect-label="Cancel"]:enabled:hover,
.p-button[ng-reflect-label="Close"],
.p-button[ng-reflect-label="Close"]:enabled:hover,
.p-button[label="Add another subject"],
.p-button[label="Add another subject"]:enabled:hover,
.p-button[label="Download PEP"],
.p-button[label="Download PEP"]:enabled:hover,
.p-button[label="Find and add a user"],
.p-button[label="Find and add a user"]:enabled:hover,
.p-datepicker-trigger.p-button:enabled:hover,
.p-datepicker-trigger.p-button,
.p-inputnumber-buttons-stacked
  .p-inputnumber-button-group
  .p-button.p-inputnumber-button {
  background-color: #ffffff !important;
  color: #666666;
  border-color: #1c798e;
}

.required-field-disclaimer {
  font-size: 0.85rem;
  padding: 0.2rem 0 0.5rem 0;
}

.padding-left-zero {
  padding-left: 0;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-a-rem {
  padding: 0 0 1rem 0;
}

.padding-zero {
  padding: 0;
}

.margin-bottom-half-rem {
  margin: 0 0 0.5rem 0;
}

.red {
  color: #ff0000;
}

.input-number-width input {
  width: 5rem;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  width: 4rem;
  border-radius: 0;
}

.p-calendar {
  width: 100%;
  margin: 0.25rem 0;
  border-radius: 0;
  height: 1.75rem;
}

.p-multiselect,
.p-dropdown {
  width: 75%;
  border-radius: 0;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker table td > span.p-highlight {
  color: #000000;
  background: #d9d9d9;
}

.p-datepicker {
  padding: 0rem;
  background: #ffffff;
  color: #595959;
  border: 1px solid #d9d9d9;
  border-radius: 0px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 1px solid #595959;
  box-shadow: 0;
}

.p-datepicker .p-datepicker-header {
  color: #595959;
  font-weight: 600;
}

.p-datepicker table {
  font-size: 0.85rem;
  margin: 0;
}
.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  border-radius: 0;
}

.margin-auto {
  margin: auto;
}

label {
  font-size: 0.9rem;
  font-weight: normal;
  margin: 1rem 0;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
}

.p-inputtext {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 0.85rem;
  color: #595959;
  background: #ffffff;
  padding: 0.3rem 0.4rem;
  border: 1px solid #a6a6a6;
  border-radius: 0px;
}

.p-multiselect .p-multiselect-label,
.p-dropdown .p-inputtext {
  padding: 0.1rem 0.4rem;
}

.p-inputnumber {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  color: #595959;
  background: #ffffff;
  padding: 0.25rem 0;
  border-radius: 0px;
  height: 2.25rem;
}

.p-multiselect,
.p-dropdown {
  height: 1.75rem;
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-size: 0.8rem;
  color: #595959;
  background: #ffffff;
  padding: 0.25rem 0;
  border: 1px solid #a6a6a6;
  border-radius: 0px;
}

.p-table {
  padding-bottom: 0.5rem;
}
hr {
  border: 0;
  border-top: 1px solid #d5d5d5;
}

.mydialog {
  width: 50vw;
}


.p-dropdown .p-dropdown-label.p-placeholder {
  color: #595959;
}

.p-inputtext .p-placeholder {
  color: #d9d9d9;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.1rem 0.5rem;
  color: #595959;
  font-size: 0.85rem;
}
.p-listbox:not(.p-disabled)
  .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #595959;
  background: #eaeaea;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #595959;
  background: #d9d9d9;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #595959;
  box-shadow: inset 0 0 0 0.2rem #ffdc00;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  box-shadow: inset 0 0 0 0.2rem #ffdc00;
}

.padding-left-half-rem {
  padding-left: 0.5rem;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border: 1px solid #a6a6a6;
  background: #ffffff;
}

.header-width-small {
  width: 10%;
}

.header-width-large {
  width: 16%;
}

.margin-left-75 {
  margin-left: 0.75rem;
}

.margin-padding-bottom-0 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.progress-spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.p-progress-spinner-circle {
  animation: none !important;
  stroke: blue !important;
}

large-button-width {
  width: 225px;
}

button-width-auto {
  width: auto;
}

.p-dialog .p-dialog-footer button{
  width: 175px;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button{
  min-width: 25px;
}

.p-calendar-w-btn .p-datepicker-trigger {
  min-width: 25px;
}
